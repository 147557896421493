import { useEffect } from 'react';
import toast, { useToasterStore } from 'react-hot-toast';

export function ToastLimit() {
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 2;

  useEffect(() => {
    for (let i = 0; i < toasts.length; i++) {
      const t = toasts[i];
      t.visible && i >= TOAST_LIMIT && toast.dismiss(t.id);
    }
  }, [toasts]);

  return null;
}
