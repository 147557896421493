import { isAnyOf, Middleware } from '@reduxjs/toolkit';
import { addQueryAdsParams, clearQueryAdsParams, removeQueryAdsParams } from 'src/redux/slices/query-ads-params.slice';
import { RootState } from 'src/redux/store';

export const queryAdsParamsMiddleware: Middleware = (api) => (next) => (action) => {
  if (isAnyOf(addQueryAdsParams, removeQueryAdsParams, clearQueryAdsParams)(action)) {
    next(action);
    const stateAfterAction: RootState = api.getState();
    localStorage.setItem('query-ads-params', JSON.stringify(stateAfterAction.queryAdsParamsReducer));
  }
};
