import { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fpost } from 'src/lib/fetch';
import { LoginReq } from '../dto/login/login.req';
import { LoginRes } from '../dto/login/login.res';

export function LoginPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const { state } = useLocation<string>();
  const navigate = useNavigate();

  if (state) {
    window.history.replaceState({}, document.title);
    toasting({
      children: (
        <span>
          新しいパスワードについては、<span className="font-medium">{state}</span> を確認してください
        </span>
      ),
      containerProps: { className: 'border-green-500' },
    });
  }

  function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    fpost<LoginReq, LoginRes>('auth/login', {
      body: {
        email: eTextEmail.current?.value || '',
        password: eTextPassword.current?.value || '',
      },
      onErrorMoveToLocation: '/dashboard/logout',
    })
      .then((res) => {
        if (res[1]?.accessToken) {
          localStorage.setItem('user', JSON.stringify(res[1]));
          navigate('/dashboard/ads');
        } else return Promise.reject(res[1]);
      })
      .catch((e) => {
        toasting({ children: '認証に失敗しました', containerProps: { className: 'border-red-600' } });
      });
  }

  return (
    <form className="mt-24 flex flex-col items-center justify-center gap-2 p-24 login-form-wrap-top" ref={eForm}>
      <h1 className="basis-full text-center text-3xl">ログイン</h1>

      <TextInput
        labelProps={{
          children: 'メールアドレス',
        }}
        type="email"
        containerProps={{
          className: 'mt-12 basis-full',
          style: {
            minWidth: '24rem',
            maxWidth: '48rem',
          },
        }}
        required
        ref={eTextEmail}
      />

      <TextInput
        labelProps={{
          children: 'パスワード',
        }}
        type="password"
        containerProps={{
          className: 'mt-4 basis-full',
          style: {
            minWidth: '24rem',
            maxWidth: '48rem',
          },
        }}
        required
        ref={eTextPassword}
      />

      <Button
        className="mx-auto mt-12 rounded-md bg-black text-xs text-white"
        style={{
          minWidth: '256px',
        }}
        type="submit"
        onClick={handleButtonSubmit}
        ref={eButtonSubmit}>
          ログイン
      </Button>

      <Link to="/dashboard/passwordReset" className="mt-2 hover:text-sky-800 hover:underline hover:underline-offset-2">
        パスワードを再設定する
      </Link>
    </form>
  );
}
