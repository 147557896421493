import { TextInput as FlowbiteTextInput, TextInputProps } from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const TextInput = React.forwardRef<
  HTMLInputElement,
  TextInputProps & { labelProps?: Props<'label'>; containerProps?: Props<'div'> }
>(({ id = uniqueId('TextInput-'), labelProps, containerProps, ...props }, ref) => {
  const e = useRef<HTMLInputElement>({} as HTMLInputElement);

  useEffect(() => {
    if (e.current)
      e.current.className = twMerge(
        e.current.className,
        `${props.icon ? 'pl-10 ' : ''}rounded-md disabled:bg-transparent`,
        props.className,
      );
  }, []);

  useImperativeHandle(ref, () => e.current);

  return (
    <div {...containerProps}>
      <label
        {...labelProps}
        className={twMerge('mb-2 block text-sm font-normal text-gray-900 dark:text-white', labelProps?.className)}
        htmlFor={id}
      />
      <FlowbiteTextInput id={id} {...props} ref={e} />
    </div>
  );
});
