import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table } from 'src/lib/components/Table.component';
import { fdelete, fget } from 'src/lib/fetch';
import { UserRole } from '../../dto/login/login.res';
// import { ResponseListDocuments } from '../../dto/response';
import { UserEntity } from '../../dto/user/user.entity';
import { DeleteUserReq } from '../../dto/user/user.req';

export function UsersPage() {
  const [users, setUsers] = useState<UserEntity[]>();

  const navigate = useNavigate();

  // サイドヘッダーオープン
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('open');
    } else {
      document.body.classList.remove('open');
    }
  }, [isOpen]);
  // サイドヘッダーオープン END

  function handleButtonDelete(input: DeleteUserReq) {
    fdelete<DeleteUserReq>('api/user', {
      body: input,
      onErrorMoveToLocation: '/dashboard/logout',
    }).then(() => {
      getUserList();
    });
  }

  function getUserList() {
    fget<any, typeof users>('/user/getUsers', {
      onErrorMoveToLocation: '/dashboard/logout',
    }).then((data) => {
      setUsers(data[1]);
    });
  }

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-16 py-10 dashboard-wrap-top">
      <style>
            {`@media screen and (max-width: 576px) { 
              /* テーブル */
              table th:nth-child(2),
              table td:nth-child(2) {
                width: calc(200px + (15px*2));
              }
              table th:nth-child(4),
              table td:nth-child(4),
              table th:nth-child(5),
              table td:nth-child(5) {
                width: calc(40px + (15px*2));
                text-align: center;
              }
            }
            @media screen and (min-width: 577px) and (max-width: 768px) {
              table th:nth-child(2),
              table td:nth-child(2) {
                width: calc(200px + (15px*2));
              }
            }`}
      </style>
      <div className='login-header-wrap'>
          <h1 className="text-2xl">ユーザー管理</h1>
          <div className={`dashboard-btn-div cp_hmenuac01${isOpen ? ' active' : ''}`} onClick={handleClick}><span className="cp_bar"></span></div>
        </div>
      <span
        className="w-full border-b border-slate-800"
        style={{
          minWidth: '24rem',
        }}></span>

      <div className="flex basis-full">
        <Button
          className="mt-4 ml-auto rounded-md bg-black text-white"
          onClick={(e) => {
            navigate('create');
          }}>
          <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
          新規作成
        </Button>
      </div>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md dashboard-table-div">
        <Table
          headCellData={['氏名', 'メールアドレス', '役割', '', '']}
          cellData={users?.map((user) =>
            [
              user.username,
              <a href="#" className="text-blue-500 hover:underline">
                {user.email}
              </a>,
              user.role,
              <Link to="edit" state={user} className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                詳細
              </Link>,
            ].concat(
              ...(user.role !== UserRole.ADMIN
                ? [
                    <a
                      href="#"
                      className="font-medium text-red-500 hover:underline dark:text-blue-500"
                      onClick={() => {
                        fdelete(`user/getUserById/${user._id}`).then(() => {
                          getUserList();
                        });
                      }}>
                      削除
                    </a>,
                  ]
                : ['']),
            ),
          )}
          hideCheckbox
        />
      </div>
    </div>
  );
}
