import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export interface PaginationRef {
  page: number;
}

export const Pagination = forwardRef<
  PaginationRef,
  Props<'ul'> & {
    total: number;
    defaultPageValue?: number;
    size?: number;
    iconPrevious?: React.ReactNode;
    iconNext?: React.ReactNode;
    hops?: number | 'lastpoint';
    onPageChange?: (page: number) => boolean | void;
  }
>(
  (
    {
      id = uniqueId('Pagination-'),
      total,
      defaultPageValue = 1,
      size = 5,
      iconNext,
      iconPrevious,
      hops = 1,
      onPageChange,
      ...props
    },
    ref,
  ) => {
    const e = useRef<PaginationRef>({ page: defaultPageValue });

    const [page, setPage] = useState<number>(defaultPageValue);

    const listPage = () => {
      const temp = Array.from({ length: size * 2 + 1 }, (_, k) => {
        return k === size ? page : 0;
      });

      for (let i = size - 1; i >= 0; i--) {
        temp[i] = temp[i + 1] - 1;

        if (temp[i] <= 0) temp[i] = -1;
      }

      for (let i = size + 1; i < temp.length; i++) {
        temp[i] = temp[i - 1] + 1;

        if (temp[i] > total || temp[i] === 0) temp[i] = -1;
      }

      return temp
        .filter((v) => v !== -1)
        .map((v) => ({
          value: v,
          selected: v === page,
        }));
    };

    useEffect(() => {
      e.current.page = page;
      onPageChange && onPageChange(page);
    }, [page]);

    useImperativeHandle(ref, () => e.current);

    return total ? (
      <ul {...props} className={twMerge('inline-flex items-center -space-x-px', props.className)}>
        <li>
          <a
            href="#"
            className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              switch (hops) {
                case 'lastpoint':
                  setPage(1);
                  break;
                default:
                  setPage(page - hops);
                  break;
              }
            }}>
            <span className="sr-only">Previous</span>
            {iconPrevious ? (
              iconPrevious
            ) : (
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"></path>
              </svg>
            )}
          </a>
        </li>

        {listPage().map(({ value, selected }, i) => (
          <li
            key={i}
            onClick={() => {
              setPage(value);
            }}>
            {selected ? (
              <a
                href="#"
                aria-current="page"
                className="z-10 border border-blue-300 bg-blue-50 px-3 py-2 leading-tight text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">
                {value}
              </a>
            ) : (
              <a
                href="#"
                className="border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                {value}
              </a>
            )}
          </li>
        ))}
        <li>
          <a
            href="#"
            className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              switch (hops) {
                case 'lastpoint':
                  setPage(total);
                  break;
                default:
                  setPage(page + hops);
                  break;
              }
            }}>
            <span className="sr-only">Next</span>
            {iconNext ? (
              iconNext
            ) : (
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"></path>
              </svg>
            )}
          </a>
        </li>
      </ul>
    ) : (
      <></>
    );
  },
);
