import { LoginRes, UserRole } from './../../modules/dashboard/dto/login/login.res';
import { environment } from './../../environments/environment';
import { globalNavigate } from '../react-router-dom/global-history';

interface ReqInit<Body> extends Omit<RequestInit, 'body'> {
  body?: Body;
  query?: Record<string, string>;
  onErrorMoveToLocation?: string;
}

export function getUrl(path: string | URL, base: string | URL | undefined, query?: ReqInit<any>['query']) {
  const url = new URL(path, environment.enpoint);

  const queryEntries = Object.entries(query || {});

  for (let i = 0; i < queryEntries.length; i++) url.searchParams.set(queryEntries[i][0], queryEntries[i][1]);

  return url;
}

export function getBody(init?: ReqInit<any>) {
  return init?.body && !(init?.body instanceof FormData) ? JSON.stringify(init?.body) : init?.body;
}

export function getUser(): LoginRes | null {
  return JSON.parse(String(localStorage.getItem('user')));
}

export function isUserHasRoles(roles: (UserRole | undefined)[]) {
  const user = getUser();

  return roles.indexOf(user?.role) !== -1;
}

export function getAuthHeader() {
  const user = getUser();
  return user ? { Authorization: `Bearer ${user.accessToken}` } : {};
}

export function fget<B = any, R = any>(path: string, init?: ReqInit<B>) {
  return fetch(getUrl(path, environment.enpoint, init?.query), {
    ...init,
    method: 'GET',
    headers: {
      ...getAuthHeader(),
      ...init?.headers,
    },
  } as any)
    .then((res) => (res.ok ? Promise.all([res.clone().text(), res.json() as Promise<R>]) : Promise.reject(res.json())))
    .catch(() => {
      init?.onErrorMoveToLocation && globalNavigate(init.onErrorMoveToLocation);

      return [];
    });
}

export function fpost<B = any, R = any>(path: string, init?: ReqInit<B>) {
  return fetch(getUrl(path, environment.enpoint, init?.query), {
    ...init,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  } as any)
    .then((res) => (res.ok ? Promise.all([res.clone().text(), res.json() as Promise<R>]) : Promise.reject(res.json())))
    .catch((e) => {
      init?.onErrorMoveToLocation && globalNavigate(init.onErrorMoveToLocation);

      return [];
    });
}

export function fput<B = any, R = any>(path: string, init?: ReqInit<B>) {
  return fetch(getUrl(path, environment.enpoint, init?.query), {
    ...init,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  } as any)
    .then((res) => (res.ok ? Promise.all([res.clone().text(), res.json() as Promise<R>]) : Promise.reject(res.json())))
    .catch(() => {
      init?.onErrorMoveToLocation && globalNavigate(init.onErrorMoveToLocation);

      return [];
    });
}

export function fdelete<B = any, R = any>(path: string, init?: ReqInit<B>) {
  return fetch(getUrl(path, environment.enpoint, init?.query), {
    ...init,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  } as any)
    .then((res) => (res.ok ? Promise.all([res.clone().text(), res.json() as Promise<R>]) : Promise.reject(res.json())))
    .catch(() => {
      init?.onErrorMoveToLocation && globalNavigate(init.onErrorMoveToLocation);

      return [];
    });
}

export function fupload<B = any, R = any>(path: string | URL, init?: ReqInit<B>) {
  return fetch(path, {
    ...init,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
  } as any)
    .then((res) => (res.ok ? Promise.all([res.clone().text(), res.json() as Promise<R>]) : Promise.reject(res.json())))
    .catch(() => {
      init?.onErrorMoveToLocation && globalNavigate(init.onErrorMoveToLocation);

      return [];
    });
}
