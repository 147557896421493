import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { QueryAdsFilter } from 'src/modules/ads/dto/ads.req';

class AppLocalStorage {
  get queryAdsParams(): QueryAdsFilter | null {
    return JSON.parse(localStorage.getItem('query-ads-params') || 'null');
  }
}

export const LocalStorage = new AppLocalStorage();

const initialState: QueryAdsFilter = LocalStorage.queryAdsParams || {};

export const queryAdsParamsSlice = createSlice({
  name: 'queryAdsParamsSlice',
  initialState,
  reducers: {
    addQueryAdsParams: (
      state,
      action: PayloadAction<{
        key: keyof QueryAdsFilter;
        value: any;
      }>,
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const newState: typeof state = { ...(LocalStorage.queryAdsParams || {}) };

      const isArray =
        (['searchString', 'sortBy', 'imported'] as typeof action.payload.key[]).indexOf(action.payload.key) === -1;

      if (isArray) {
        newState[action.payload.key] = Array.from(
          new Set([action.payload.value].concat(newState[action.payload.key] || [])),
        ) as any;
      } else {
        newState[action.payload.key] = action.payload.value;
      }

      return newState;
    },
    removeQueryAdsParams: (
      state,
      action: PayloadAction<{
        key: keyof QueryAdsFilter;
        value?: any;
      }>,
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const newState: typeof state = { ...(LocalStorage.queryAdsParams || {}) };

      const isArray =
        (['searchString', 'sortBy', 'imported'] as typeof action.payload.key[]).indexOf(action.payload.key) === -1;

      if (isArray) {
        newState[action.payload.key] = Array.from(
          new Set(((newState[action.payload.key] as []) || []).filter((v) => v !== action.payload.value)),
        ) as any;

        if (!(newState[action.payload.key] as any).length) delete newState[action.payload.key];
      } else {
        delete newState[action.payload.key];
      }

      return newState;
    },
    clearQueryAdsParams: () => {
      return {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { addQueryAdsParams, removeQueryAdsParams, clearQueryAdsParams } = queryAdsParamsSlice.actions;

export const queryAdsParamsReducer = queryAdsParamsSlice.reducer;
