import React, { useImperativeHandle, useRef } from 'react';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const InputFile = React.forwardRef<
  HTMLInputElement,
  Props<'input'> & {
    label?: React.ReactNode;
    labelInnerProps?: Props<'label'>;
    containerProps?: Props<'div'>;
    helper?: React.ReactNode;
  }
>(
  (
    { id = uniqueId('InputFile-'), label, labelInnerProps, helper, children, containerProps, className = '', ...props },
    ref,
  ) => {
    const e = useRef<HTMLInputElement>({} as HTMLInputElement);

    const eFileName = useRef<HTMLSpanElement>(null);

    useImperativeHandle(ref, () => e.current);

    return (
      <div {...containerProps}>
        <label htmlFor={id} className="block font-medium text-gray-900 dark:text-white">
          {label}
        </label>

        <label
          htmlFor={id}
          className={'inline-flex cursor-pointer flex-col rounded-md border border-gray-300 px-5 py-2.5 text-center text-sm font-medium hover:bg-sky-600 hover:text-white focus:ring-4 focus:ring-blue-300 '.concat(
            labelInnerProps?.className || '',
          )}>
          {children}
          <input
            id={id}
            className={'hidden '.concat(className)}
            {...props}
            type="file"
            ref={e}
            onChange={(e) => {
              if (eFileName.current) eFileName.current.innerHTML = e.target.value;
            }}
          />
          <span ref={eFileName}></span>
        </label>

        <p id={`${id}-helper`} className="text-sm text-gray-500 dark:text-gray-400">
          {helper}
        </p>
      </div>
    );
  },
);
