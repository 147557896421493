import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table } from 'src/lib/components/Table.component';
import { fget } from 'src/lib/fetch';
import { environment } from '../../../../environments/environment';
import { ListImportDto } from '../../dto/list-import/ListImport.dto';

export function ListImportPage() {
  const [imports, setImport] = useState<ListImportDto[]>();

  const navigate = useNavigate();

  // サイドヘッダーオープン
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('open');
    } else {
      document.body.classList.remove('open');
    }
  }, [isOpen]);
  // サイドヘッダーオープン END

  useEffect(() => {
    fget<any, typeof imports>('/list-import', {
      onErrorMoveToLocation: '/dashboard/logout',
    }).then((data) => {
      setImport(data[1]);
    });
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-16 py-10 dashboard-wrap-top">
      <style>
            {`@media screen and (max-width: 576px) { 
              /* テーブル */
              table th:last-child,
              table td:last-child {
                width: calc(350px + (15px*2));
              }
            }
            @media screen and (min-width: 577px) and (max-width: 768px) {
              table th:last-child,
              table td:last-child {
                width: calc(400px + (15px*2));
              }
            }`}
      </style>
      <div className='login-header-wrap'>
          <h1 className="text-2xl">インポートリスト</h1>
          <div className={`dashboard-btn-div cp_hmenuac01${isOpen ? ' active' : ''}`} onClick={handleClick}><span className="cp_bar"></span></div>
        </div>
      <span
        className="w-full border-b border-slate-800 dashboard-border-span"
        style={{
          minWidth: '24rem',
        }}></span>

      <Button
        className="mt-4 ml-auto whitespace-nowrap bg-black px-4 text-white"
        onClick={(e) => {
          navigate('import');
        }}>
        <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        インポート
      </Button>
      {/* </div> */}

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md dashboard-table-div">
        <Table
          headCellData={['氏名', 'ファイル名', '作成日時']}
          cellData={imports?.map((data) => [
            data.user?.username,
            <a
              target={'_blank'}
              className="inline-block max-w-[16rem] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap font-medium text-blue-500 hover:underline"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (data.fileId) open(environment.enpoint + 'list-import/file/' + data.fileId);
                return true;
              }}>
              {data.fileName}
            </a>,
            new Date(data.createdAt).toString(),
          ])}
          hideCheckbox
        />
      </div>
    </div>
  );
}
