import { queryAdsParamsMiddleware } from './middlewares/query-ads-params.middleware';
import { configureStore } from '@reduxjs/toolkit';
import { queryAdsParamsReducer } from './slices/query-ads-params.slice';

export const store = configureStore({
  reducer: {
    queryAdsParamsReducer: queryAdsParamsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(queryAdsParamsMiddleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
