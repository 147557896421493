import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fget, fpost } from 'src/lib/fetch';
import { PasswordResetReq } from '../dto/password-reset/password-reset.req';

export function ResetPasswordPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextEmail = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    fpost<PasswordResetReq, { success: boolean }>('auth/passwordReset', {
      body: {
        email: eTextEmail.current?.value || '',
      },
      onErrorMoveToLocation: '/dashboard/logout',
    }).then(([_, res]) => {
      if (res.success) navigate('/dashboard/login', { state: eTextEmail.current?.value || '' });
      else
        toasting({
          children: 'このメールアドレスは有効でないかサポートされていません。',
          containerProps: { className: 'border-red-600' },
        });
    });
  }

  return (
    <form className="mt-24 grid grid-cols-1 justify-items-center gap-4 p-24 login-form-wrap-top" ref={eForm}>
      <h1 className="text-center text-3xl">パスワードを再設定する</h1>

      <TextInput
        labelProps={{
          children: 'メールアドレス',
        }}
        type="email"
        containerProps={{
          className: 'mt-12',
          style: {
            minWidth: '24rem',
          },
        }}
        required
        ref={eTextEmail}
      />

      {/* <TextInput
          labelProps={{
            children: 'パスワード レベル 2',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          ref={eTextPassword2}
        />

        <TextInput
          labelProps={{
            children: '新しいパスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          ref={eTextPassword}
        />

        <TextInput
          labelProps={{
            children: 'パスワード再入力',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          ref={eTextRePassword}
        /> */}

      <Button
        className="mx-auto mt-8 rounded-md bg-black text-xs text-white"
        style={{
          minWidth: '256px',
        }}
        type="submit"
        onClick={handleButtonSubmit}
        ref={eButtonSubmit}>
        パスワードを再設定する
      </Button>

      <Link
        className="select-none text-center hover:underline hover:underline-offset-2"
        to="/dashboard/login"
        style={{
          minWidth: '256px',
        }}>
        戻る
      </Link>
    </form>
  );
}

export function ChangePasswordResetPage() {

  const [isToken, setIsToken] = useState<boolean>(true);
  const eForm = useRef<HTMLFormElement>(null);

  const eTextPassword = useRef<HTMLInputElement>(null);
  const eTextRePassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fget<any>(`auth/changePassword/${token}`, {
      // onErrorMoveToLocation: '/dashboard/logout',
    }).then((res:any) => {
      if(res[1]?.success){
        console.log(res[1]?.success)
        setIsToken(false)
      }
    });

  }, []);

  

  function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    fpost(`auth/changePassword/${token}`, {
      body: {
        newPass: eTextPassword.current?.value || '',
      },
      onErrorMoveToLocation: '/dashboard/logout',
    }).finally(() => {
      navigate('/dashboard/login');
    });
  }

  return (
    <>
    {isToken?<p className="mt-24 grid grid-cols-1 justify-items-center gap-4 p-24 text-2xl">トークンの有効期限が切れています</p> :
    // 
    
    <form className="mt-24 grid grid-cols-1 justify-items-center gap-4 p-24" ref={eForm}>
      <h1 className="text-center text-3xl">パスワードを再設定する</h1>
      {/* 
      <TextInput
        labelProps={{
          children: '電子メイル',
        }}
        type="email"
        containerProps={{
          className: 'mt-12',
          style: {
            minWidth: '24rem',
          },
        }}
        required
        ref={eTextEmail}
      /> */}

      {/* <TextInput
          labelProps={{
            children: 'パスワード レベル 2',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          ref={eTextPassword2}
        /> */}

      <TextInput
        labelProps={{
          children: '新しいパスワード',
        }}
        type="password"
        containerProps={{
          className: 'mt-4 w-1/3 basis-full',
          style: {
            minWidth: '24rem',
          },
        }}
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          e.target.value !== eTextRePassword.current?.value
            ? e.target.setCustomValidity('Password and re-password does not match.')
            : [eTextPassword.current?.setCustomValidity(''), eTextRePassword.current?.setCustomValidity('')];
        }}
        ref={eTextPassword}
      />

      <TextInput
        labelProps={{
          children: 'パスワード再入力',
        }}
        type="password"
        containerProps={{
          className: 'mt-4 w-1/3 basis-full',
          style: {
            minWidth: '24rem',
          },
        }}
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          e.target.value !== eTextPassword.current?.value
            ? e.target.setCustomValidity('Password and re-password does not match.')
            : [eTextPassword.current?.setCustomValidity(''), eTextRePassword.current?.setCustomValidity('')];
        }}
        ref={eTextRePassword}
      />

      <Button
        className="mx-auto mt-8 rounded-md bg-black text-xs text-white"
        style={{
          minWidth: '256px',
        }}
        type="submit"
        onClick={handleButtonSubmit}
        ref={eButtonSubmit}>
        設定
      </Button>
    </form>
    }
     </>
  );
}
