import { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { Datepicker, DatePickerRef } from 'src/lib/components/DatePicker.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { dayts } from 'src/lib/dayjs';
import { fpost } from 'src/lib/fetch';
import {
  addQueryAdsParams,
  clearQueryAdsParams,
  LocalStorage,
  removeQueryAdsParams,
} from 'src/redux/slices/query-ads-params.slice';
import { RootState } from 'src/redux/store';
import { QueryAdsReq } from '../dto/ads.req';

export function AdsSideBar() {
  const [render, rerender] = useState(true);

  const setTimeoutOnChangeTextSearch = useRef<NodeJS.Timeout>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!render) rerender(true);
  }, [render]);

  return (
    <div
      id="ad-page-sidebar"
      className="custom-scrollbar-2 border-6 flex w-[8rem] flex-wrap overflow-auto p-1 pb-6 md:w-[16rem] md:p-4">
      <span
        id={render.toString()}
        className="ml-auto cursor-pointer text-center text-sm underline underline-offset-2 hover:text-orange-500 hover:no-underline"
        onClick={(e) => {
          rerender((prev) => !prev);
          dispatch(clearQueryAdsParams());
        }}>
        クリアフィルター
      </span>
      {render && (
        <>
          <TextInput
            type="text"
            placeholder="探す..."
            containerProps={{
              className: 'basis-full',
            }}
            icon={() => (
              <svg
                className="h-5 w-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"></path>
              </svg>
            )}
            defaultValue={LocalStorage.queryAdsParams?.searchString || ''}
            onChange={(e) => {
              clearTimeout(setTimeoutOnChangeTextSearch.current);
              setTimeoutOnChangeTextSearch.current = setTimeout(() => {
                dispatch(
                  addQueryAdsParams({
                    key: 'searchString',
                    value: e.target.value,
                  }),
                );
              }, 1111);
            }}
          />
          <span className="mt-5 basis-full text-center text-2xl">絞り込み条件</span>
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">媒体</span>
          {[
            {
              url: `http://${window.location.host}/assets/facebook-svgrepo-com.svg`,
              label: 'Facebook',
            },
            {
              url: `http://${window.location.host}/assets/instagram-svgrepo-com.svg`,
              label: 'Instagram',
            },
            {
              url: `http://${window.location.host}/assets/twitter-svgrepo-com.svg`,
              label: 'Twitter',
            },
            {
              url: `http://${window.location.host}/assets/line-svgrepo-com.svg`,
              label: 'LINE',
            },
            {
              url: `http://${window.location.host}/assets/tiktok-svgrepo-com.svg`,
              label: 'TikTok',
            },
          ].map(({ url, label }, i) => (
            <Checkbox
              key={i}
              containerProps={{ className: 'mt-2 basis-full' }}
              defaultChecked={(LocalStorage.queryAdsParams?.mediaSource || []).indexOf(label) !== -1}
              onChange={(e) => {
                e.target.checked
                  ? dispatch(
                      addQueryAdsParams({
                        key: 'mediaSource',
                        value: label,
                      }),
                    )
                  : dispatch(removeQueryAdsParams({ key: 'mediaSource', value: label }));
              }}
              labelProps={{
                children: (
                  <div className="flex gap-1">
                    <img src={url} className="h-5 w-5" />
                    <span>{label}</span>
                  </div>
                ),
              }}
            />
          ))}
          <CheckBoxGroupDateSeason />
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">素材</span>
          {[{ label: '静止画' }, { label: '動画' }].map(({ label }, i) => (
            <Checkbox
              key={i}
              containerProps={{ className: 'mt-2 basis-full' }}
              defaultChecked={(LocalStorage.queryAdsParams?.mediaType || []).indexOf(label) !== -1}
              onChange={(e) => {
                e.target.checked
                  ? dispatch(
                      addQueryAdsParams({
                        key: 'mediaType',
                        value: label,
                      }),
                    )
                  : dispatch(removeQueryAdsParams({ key: 'mediaType', value: label }));
              }}
              labelProps={{
                children: label,
              }}
            />
          ))}
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">ToC or ToB</span>
          <p className="text-xs font-light no-underline">ToC: 消費者向けの広告</p>
          <p className="text-xs font-light no-underline">ToB: 企業向けの広告</p>
          {[{ label: 'ToC' }, { label: 'ToB' }].map(({ label }, i) => (
            <Checkbox
              key={i}
              containerProps={{ className: 'mt-2 basis-full' }}
              defaultChecked={(LocalStorage.queryAdsParams?.tocTob || []).indexOf(label) !== -1}
              onChange={(e) => {
                e.target.checked
                  ? dispatch(
                      addQueryAdsParams({
                        key: 'tocTob',
                        value: label,
                      }),
                    )
                  : dispatch(removeQueryAdsParams({ key: 'tocTob', value: label }));
              }}
              labelProps={{
                children: label,
              }}
            />
          ))}
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">年齢</span>
          {[
            { label: '10 代' },
            { label: '20 代' },
            { label: '30 代' },
            { label: '40 代' },
            { label: '50 代' },
            { label: '60 代' },
            { label: '70 代' },
            { label: '80 代' },
          ].map(({ label: l }, i) => {
            const label = l.split(' ')[0];

            return (
              <Checkbox
                key={i}
                containerProps={{ className: 'mt-2 basis-full' }}
                defaultChecked={(LocalStorage.queryAdsParams?.ageRange || []).indexOf(label) !== -1}
                onChange={(e) => {
                  e.target.checked
                    ? dispatch(
                        addQueryAdsParams({
                          key: 'ageRange',
                          value: label,
                        }),
                      )
                    : dispatch(removeQueryAdsParams({ key: 'ageRange', value: label }));
                }}
                labelProps={{
                  children: l,
                }}
              />
            );
          })}
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">性別</span>
          {[{ label: '女性' }, { label: '男性' }].map(({ label }, i) => (
            <Checkbox
              key={i}
              containerProps={{ className: 'mt-2 basis-full' }}
              defaultChecked={(LocalStorage.queryAdsParams?.sex || []).indexOf(label) !== -1}
              onChange={(e) => {
                e.target.checked
                  ? dispatch(
                      addQueryAdsParams({
                        key: 'sex',
                        value: label,
                      }),
                    )
                  : dispatch(removeQueryAdsParams({ key: 'sex', value: label }));
              }}
              labelProps={{
                children: label,
              }}
            />
          ))}
          <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">業種</span>
          <CheckboxGroupIndustry />
        </>
      )}
    </div>
  );
}

export function CheckboxGroupIndustry() {
  const queryAdsFilter = useSelector((root: RootState) => root.queryAdsParamsReducer);

  const [businesses, setBusinesses] = useState<string[]>();

  const dispatch = useDispatch();

  useEffect(() => {
    fpost<Partial<QueryAdsReq>, { businessType: { _id: string }[] }>('media/business', {
      body: {
        filter: queryAdsFilter,
      },
    }).then(([_, data]) => {
      const businessesMap = new Map<string, boolean>();

      const b = data.businessType.concat(...(LocalStorage.queryAdsParams?.businessType || []).map((_id) => ({ _id })));

      for (let i = 0; i < b.length; i++) {
        const e = b[i];

        businessesMap.set(e._id, true);
      }

      setBusinesses(Array.from(businessesMap.keys()));
    });
  }, [queryAdsFilter]);

  return businesses ? (
    <div
      className="custom-scrollbar-3 -ml-1 w-full overflow-y-auto border"
      style={{
        maxHeight: '16rem',
      }}>
      {businesses.map((_id, i) => (
        <Checkbox
          key={i}
          containerProps={{ className: 'my-2 ml-1 basis-full' }}
          defaultChecked={(LocalStorage.queryAdsParams?.businessType || []).indexOf(_id) !== -1}
          onChange={(e) => {
            e.target.checked
              ? dispatch(
                  addQueryAdsParams({
                    key: 'businessType',
                    value: _id,
                  }),
                )
              : dispatch(removeQueryAdsParams({ key: 'businessType', value: _id }));
          }}
          labelProps={{
            children: _id,
          }}
        />
      ))}
    </div>
  ) : (
    <>No data</>
  );
}

export function CheckBoxGroupDateSeason() {
  const eDate = useRef<DatePickerRef>(null);

  const [mapDate, setMapDate] = useState<Map<number, Dayjs>>(
    Array.from({ length: 5 }, (_, k) => dayts().startOf('month').subtract(k, 'months'))
      .concat(...(LocalStorage.queryAdsParams?.dateSeason || []).map((v) => dayts(v)))
      .reduce((result, date) => {
        result.set(date.valueOf(), date);

        return result;
      }, new Map()),
  );

  const dispatch = useDispatch();

  function syncMapDate() {
    setMapDate(() =>
      Array.from({ length: 5 }, (_, k) => dayts().startOf('month').subtract(k, 'months'))
        .concat(...(LocalStorage.queryAdsParams?.dateSeason || []).map((v) => dayts(v)))
        .reduce((result, date) => {
          result.set(date.valueOf(), date);

          return result;
        }, new Map()),
    );
  }

  return (
    <>
      <span className="my-4 basis-full text-lg font-medium underline underline-offset-2">時期</span>
      <Datepicker
        labelProps={{
          className: 'mb-0',
        }}
        valueDisplayFormat={{
          month: 'numeric',
          year: 'numeric',
        }}
        valueFormat={{
          month: 'numeric',
          year: 'numeric',
        }}
        valueEditFormat={{
          month: 'numeric',
          year: 'numeric',
        }}
        messages={{
          moveToday: '今日',
        }}
        locale="ja"
        className="rounded-none"
        includeTime={false}
        calendarProps={{ views: ['year', 'decade', 'century'] }}
        containerProps={{
          style: {
            minWidth: '4rem',
          },
        }}
        placeholder="yyyy/m"
        onChange={(date) => {
          const valueOf = dayts(date).startOf('month').valueOf();
          dispatch(
            addQueryAdsParams({
              key: 'dateSeason',
              value: valueOf,
            }),
          );
          syncMapDate();
        }}
        ref={eDate}
      />
      <div className="custom-scrollbar-3 h-36 w-full overflow-y-auto pl-1">
        {Array.from(mapDate.values()).map((date, i) => {
          return (
            <Checkbox
              key={i}
              containerProps={{ className: 'mt-2 basis-full' }}
              checked={(LocalStorage.queryAdsParams?.dateSeason || []).indexOf(date.valueOf()) !== -1}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    addQueryAdsParams({
                      key: 'dateSeason',
                      value: date.valueOf(),
                    }),
                  );
                } else {
                  dispatch(removeQueryAdsParams({ key: 'dateSeason', value: date.valueOf() }));
                }

                syncMapDate();
              }}
              labelProps={{
                children: date.locale('JST').format('YYYY 年 MM 月'),
              }}
            />
          );
        })}
      </div>
    </>
  );
}
