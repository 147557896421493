import dayjs from 'dayjs';
import React, { ComponentType, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactPlayer, { Config } from 'react-player';
import { OnProgressProps, SourceProps } from 'react-player/base';
import { Props } from '../type/props.type';

export class VideoPlayerProps {
  url?: string | string[] | SourceProps[] | MediaStream | undefined;
  playing?: boolean;
  loop?: boolean;
  controls?: boolean;
  volume?: number;
  muted?: boolean;
  playbackRate?: number;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties | undefined;
  progressInterval?: number;
  playsinline?: boolean;
  playIcon?: ReactElement;
  previewTabIndex?: number | null;
  pip?: boolean;
  stopOnUnmount?: boolean;
  light?: boolean | string | ReactElement;
  fallback?: ReactElement;
  wrapper?: ComponentType<{ children: ReactNode }>;
  onReady?: (player: ReactPlayer) => void;
  onStart?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onEnded?: () => void;
  onClickPreview?: (event: any) => void;
  onEnablePIP?: () => void;
  onDisablePIP?: () => void;
  onError?: (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => void;
  onDuration?: (duration: number) => void;
  onSeek?: (seconds: number) => void;
  onProgress?: ((state: OnProgressProps) => void) | undefined;
  config?: Config;
  containerProps?: Props<'div'>;
}

export class VideoPlayerRef {
  e: ReactPlayer;
  setProps: (eProps: VideoPlayerProps) => void;
}

export const VideoPlayer = React.forwardRef<VideoPlayerRef, VideoPlayerProps>(({ containerProps, ...props }, ref) => {
  const e = useRef<ReactPlayer>({} as ReactPlayer);

  const [eProps, setEProps] = useState<typeof props>(props);

  useImperativeHandle(ref, () => ({
    e: e as any,
    setProps: (eProps) => {
      setEProps((prevProps) => ({ ...prevProps, ...eProps }));
    },
  }));

  return (
    <div {...containerProps}>
      <ReactPlayer {...eProps} ref={e} />
    </div>
  );
});
