import { useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fget, getUser } from 'src/lib/fetch';
import { AdsHeader } from 'src/modules/ads/components/AdsHeader.component';
import { DashboardSideBar } from './DashboardSideBar.component';

export function DashboardContainer() {
  const navigate = useNavigate();
  const user = getUser();
  const { state, pathname, search } = useLocation<string>();

  // const isAuthenticated = (user?.loginTime || 0) / 1000 + (user?.expiresInSeconds || 0) > dayjs().valueOf();
  const isAuthenticated = pathname.startsWith('/dashboard/passwordReset') ? false : Boolean(user);
  !isAuthenticated && localStorage.removeItem('user');

  useEffect(() => {
    if (pathname.indexOf('login') === -1 && pathname.indexOf('passwordReset') === -1)
      fget('auth/getUser').then(([_, data]) => {
        if (!data) navigate('/dashboard/logout');
          if(user){
              user.email =data.email
              user.role = data.role
              user.username = data.username
              localStorage.setItem('user', JSON.stringify(user));
          }

      });

    navigate(
      isAuthenticated ? pathname + search : pathname.startsWith('/dashboard/passwordReset') ? pathname : 'login',
      {
        state,
      },
    );
  }, []);

  return isAuthenticated ? (
    <>
      <DashboardSideBar />
      <Outlet />
    </>
  ) : (
    <>
      <AdsHeader />
      <Outlet context={state} />
    </>
  );
}
