import { Toaster } from 'react-hot-toast';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastLimit } from './lib/components/ToastLimit.component';
import { AdsContainer } from './modules/ads/components/AdsContainer.component';
import { AdsContent } from './modules/ads/components/AdsContent.component';
import { AdPage } from './modules/ads/pages/Ad.page';
// import { AdsPage } from './modules/ads/pages/Ads.page';
import { AdsClientPage } from './modules/ads/pages/AdsClient.page';
import { DashboardContainer } from './modules/dashboard/components/DashboardContainer.component';
import { DashboardContent } from './modules/dashboard/components/DashboardContent.component';
import './modules/dashboard/css/dashboard.css';
import { UserRole } from './modules/dashboard/dto/login/login.res';
import { ApproveListPage } from './modules/dashboard/pages/approve-list/ApproveList.page';
import { AdsPage } from './modules/dashboard/pages/list-ads/Ads.page';
import { CreateAdsPage } from './modules/dashboard/pages/list-ads/CreateAds.page';
import { EditAdPage } from './modules/dashboard/pages/list-ads/EditAd.page';
import { ImportAdsPage } from './modules/dashboard/pages/list-ads/ImportAds.page';
import { ListImportPage } from './modules/dashboard/pages/list-import/ListImport.page';
import { LoginPage } from './modules/dashboard/pages/Login.page';
import { LogoutPage } from './modules/dashboard/pages/Logout.page';
import { ChangePasswordResetPage, ResetPasswordPage } from './modules/dashboard/pages/ResetPassword.page';
import { CreateUserPage } from './modules/dashboard/pages/user/CreateUser.page';
import { EditUserPage } from './modules/dashboard/pages/user/EditUser.page';
import { UsersPage } from './modules/dashboard/pages/user/Users.page';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AdsContainer />}>
          <Route
            index
            element={
              <AdsContent>
                <AdsClientPage />
              </AdsContent>
            }
          />
          <Route
            path="ad/:id"
            element={
              <AdsContent>
                <AdPage />
              </AdsContent>
            }
          />
        </Route>

        <Route path="/dashboard" element={<DashboardContainer />}>
          <Route
            path="login"
            element={
              <DashboardContent className="!ml-0 !w-full">
                <LoginPage />
              </DashboardContent>
            }
          />
          <Route path="logout" element={<LogoutPage />} />

          <Route
            path="passwordReset"
            element={
              <DashboardContent className="!ml-0 !w-full">
                <Outlet />
              </DashboardContent>
            }>
            <Route index element={<ResetPasswordPage />} />
            <Route path=":token" element={<ChangePasswordResetPage />} />
          </Route>

          <Route
            path="ads"
            element={
              <DashboardContent>
                <Outlet />
              </DashboardContent>
            }>
            <Route index element={<AdsPage />} />

            <Route path="create" element={<CreateAdsPage />} />
            <Route path=":id" element={<EditAdPage />} />
          </Route>

          <Route
            path="listImports"
            element={
              <DashboardContent roles={[UserRole.ADMIN]}>
                <Outlet />
              </DashboardContent>
            }>
            <Route index element={<ListImportPage />} />

            <Route path="import" element={<ImportAdsPage />} />
          </Route>

          <Route
            path="approveList"
            element={
              <DashboardContent roles={[UserRole.ADMIN]}>
                <Outlet />
              </DashboardContent>
            }>
            <Route index element={<ApproveListPage />} />
            <Route path=":id" element={<EditAdPage />} />
          </Route>

          <Route
            path="me"
            element={
              <DashboardContent>
                <EditUserPage />
              </DashboardContent>
            }
          />

          <Route
            path="users"
            element={
              <DashboardContent roles={[UserRole.ADMIN]}>
                <Outlet />
              </DashboardContent>
            }>
            <Route index element={<UsersPage />} />

            <Route path="create" element={<CreateUserPage />} />
            <Route path="edit" element={<EditUserPage />} />
          </Route>

          <Route path="" element={<DashboardContent></DashboardContent>} />
        </Route>

        <Route path="*" element={<Navigate to="/dashboard/ads" />} />
      </Routes>
      <Toaster />
      <ToastLimit />
    </>
  );
}

export default App;
