import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { fdelete, fput, getUser } from 'src/lib/fetch';
import { Select } from '../../../../lib/components/Select.component';
import { UserEntity } from '../../dto/user/user.entity';
import { CreateUserReq, DeleteUserReq } from '../../dto/user/user.req';

export function EditUserPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextUsername = useRef<HTMLInputElement>(null);
  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextRole = useRef<HTMLSelectElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);
  const eTextRePassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const { state } = useLocation<UserEntity>();

  const eButtonModeEdit = useRef<HTMLInputElement>(null);

  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const currentUser = getUser();

    // サイドヘッダーオープン
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };
  
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('open');
      } else {
        document.body.classList.remove('open');
      }
    }, [isOpen]);
    // サイドヘッダーオープン END

  function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();
    const userId = state ? state._id : currentUser?.id;

    if(eTextPassword.current){
      if (eTextPassword.current?.value != eTextRePassword.current?.value) {
        eTextRePassword?.current?.setCustomValidity('Password does not match.')
        return;
      }
    }

    // api/user/create
    fput<CreateUserReq>(`user/update/${userId}`, {
      body: {
        username: eTextUsername.current?.value || '',
        email: eTextEmail.current?.value || '',
        role: eTextRole.current?.value || '',
        password: eTextPassword.current?.value || '',
      },
      onErrorMoveToLocation: '/dashboard/logout',
    }).then(() => {
      if (state) {
        navigate('..');
      } else {
        setCheckedModeEdit(false)
      }
    });
  }

  function handleButtonDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    fdelete<DeleteUserReq>('api/user', {
      onErrorMoveToLocation: '/dashboard/logout',
    }).then(() => {
      navigate('..');
    });
  }

  return (
    <form className="flex flex-row flex-wrap px-16 py-10 dashboard-wrap-top" ref={eForm}>
      <div className='login-header-wrap'>
          <h1 className="text-2xl">{state?'ユーザー詳細': 'ユーザー情報'}</h1>
          <div className={`dashboard-btn-div cp_hmenuac01${isOpen ? ' active' : ''}`} onClick={handleClick}><span className="cp_bar"></span></div>
        </div>
      <span
        className="w-full border-b border-slate-800"
        style={{
          minWidth: '24rem',
        }}></span>

      <div className="flex basis-full justify-end">
        <Checkbox
          className="hidden"
          onChange={(e) => setCheckedModeEdit(e.target.checked)}
          defaultChecked={checkedModeEdit}
          ref={eButtonModeEdit}
          containerProps={{
            className: `mt-4 ml-auto border border-gray-500`,
          }}
          labelProps={{
            children: '編集モード',
            className: `select-none p-2 ${checkedModeEdit ? '' : 'bg-black text-white'}`,
          }}
        />
      </div>

      <div className="flex w-2/5 flex-row flex-wrap edit-wrap-top">
        {/* <TextInput
          labelProps={{
            children: 'ID',
          }}
          type="text"
          defaultValue={state ? state._id : currentUser?.id}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          readOnly
          disabled
          required
        /> */}

        <TextInput
          labelProps={{
            children: '氏名',
          }}
          type="text"
          defaultValue={state ? state.username : currentUser?.username}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextUsername}
          disabled={!checkedModeEdit}
        />

        <TextInput
          labelProps={{
            children: 'メールアドレス',
          }}
          type="email"
          defaultValue={state ? state.email : currentUser?.email}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextEmail}
          disabled={!checkedModeEdit}
        />

        {/*<TextInput*/}
        {/*  labelProps={{*/}
        {/*    children: '役割',*/}
        {/*  }}*/}
        {/*  type="text"*/}
        {/*  defaultValue={state?.role}*/}
        {/*  containerProps={{*/}
        {/*    className: 'mt-4 w-1/3 basis-full',*/}
        {/*    style: {*/}
        {/*      minWidth: '24rem',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  ref={eTextPassword}*/}
        {/*  disabled={!checkedModeEdit}*/}
        {/*/>*/}

        {currentUser?.role =="ADMIN"?<Select
            className="rounded-md"
            labelProps={{
              children: '役割',
            }}
            defaultValue={state ? state.role : currentUser?.role}
            containerProps={{
              className: "mt-4 w-1/3 basis-full',*/",
              style: {
                minWidth: '24rem',
              },
            }}
            ref={eTextRole}
            disabled={!checkedModeEdit}>
          <option value="USER">USER</option>
          <option value="ADMIN">ADMIN</option>
        </Select> :''}


        {/*<TextInput*/}
        {/*  labelProps={{*/}
        {/*    children: 'パスワード',*/}
        {/*  }}*/}
        {/*  type="password"*/}
        {/*  containerProps={{*/}
        {/*    className: 'mt-4 w-1/3 basis-full',*/}
        {/*    style: {*/}
        {/*      minWidth: '24rem',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  ref={eTextPassword}*/}
        {/*  disabled={!checkedModeEdit}*/}
        {/*/>*/}

        {/*<TextInput*/}
        {/*  labelProps={{*/}
        {/*    children: '再パスワード',*/}
        {/*  }}*/}
        {/*  type="password"*/}
        {/*  containerProps={{*/}
        {/*    className: 'mt-4 w-1/3 basis-full',*/}
        {/*    style: {*/}
        {/*      minWidth: '24rem',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  ref={eTextRePassword}*/}
        {/*  disabled={!checkedModeEdit}*/}
        {/*/>*/}

        {!state ? (
          <>
            <TextInput
              labelProps={{
                children: 'パスワード',
              }}
              type="password"
              placeholder={'*****'}
              containerProps={{
                className: 'mt-4 w-1/3 basis-full',
                style: {
                  minWidth: '24rem',
                },
              }}
              ref={eTextPassword}
              disabled={!checkedModeEdit}
            />

            <TextInput
              labelProps={{
                children: 'パスワード再入力',
              }}
              type="password"
              containerProps={{
                className: 'mt-4 w-1/3 basis-full',
                style: {
                  minWidth: '24rem',
                },
              }}
              placeholder={'*****'}
              ref={eTextRePassword}
              disabled={!checkedModeEdit}
            />
          </>
        ) : (
          ''
        )}

        <div
          className="mt-8 flex basis-full flex-col items-center"
          style={{
            minWidth: '24rem',
          }}>
          {checkedModeEdit && (
            <>
              <Button
                className="w-1/2 basis-full justify-center rounded-md bg-black text-xs text-white btn-submit"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                確定
              </Button>
              {/*<Button*/}
              {/*  className="mt-4 w-1/2 basis-full justify-center rounded-md bg-transparent text-xs text-orange-600"*/}
              {/*  style={{*/}
              {/*    minWidth: '256px',*/}
              {/*  }}*/}
              {/*  type="submit"*/}
              {/*  onClick={handleButtonDelete}*/}
              {/*  ref={eButtonDelete}>*/}
              {/*  削除*/}
              {/*</Button>*/}
            </>
          )}

          {state && (
            <Link
              className="col-span-full mx-auto mt-4 min-w-[3rem] cursor-pointer select-none text-center hover:underline hover:underline-offset-4 link-return"
              to="..">
              戻る
            </Link>
          )}
        </div>
      </div>
    </form>
  );
}
