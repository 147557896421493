import React, { useState, useEffect } from 'react';
import { environment } from 'src/environments/environment';

export const AdsHeader = React.memo(() => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('active-sidebar');
    } else {
      document.body.classList.remove('active-sidebar');
    }
  }, [isActive]);

  return (
    <header
      id="ad-page-header"
      className={`flex w-screen justify-center border-b border-solid border-blue-400 bg-slate-50 text-slate-700`}
    >
      <img src="/logo.png" alt="logo" />
      <div className={`cp_hmenuac01 ${isActive ? 'active' : ''}`} onClick={handleClick}>
        <span className="cp_bar"></span>
      </div>
    </header>
  );
});
