import {
  Checkbox,
  CheckboxProps,
  Table as FlowBiteTable,
  TableBodyProps,
  TableCellProps,
  TableHeadCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';

export interface TableRefObject {
  selected: () => Map<number, React.ReactNode[]>;
  headCheckbox: () => HTMLInputElement | null;
}

export const Table = React.forwardRef<
  TableRefObject,
  TableProps & {
    headProps?: TableHeadProps;
    headCellProps?: TableHeadCellProps;
    headCellData?: React.ReactNode[];

    bodyProps?: TableBodyProps;
    rowProps?: TableRowProps;
    cellProps?: TableCellProps;
    cellData?: React.ReactNode[][];

    checkboxProps?: CheckboxProps;
    hideCheckbox?: boolean;
  }
>(
  (
    {
      id = uniqueId('Table-'),
      headProps,
      headCellProps,
      headCellData,

      bodyProps,
      rowProps,
      cellProps,
      cellData = [],

      checkboxProps,
      hideCheckbox = false,

      ...props
    },
    customRef,
  ) => {
    const eCheckBoxSelectAll = useRef<HTMLInputElement>(null);

    const listECheckBoxItem = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
      listECheckBoxItem.current = listECheckBoxItem.current.slice(0, cellData.length || 0);

      const e = document.getElementById(id)?.parentElement as HTMLDivElement;

      if (e) e.className = twMerge(e.className, 'custom-scrollbar-1 basis-full');
    }, []);

    useImperativeHandle(customRef, () => ({
      selected() {
        const result: Map<number, React.ReactNode[]> = new Map();

        for (let i = listECheckBoxItem.current.length - 1; i >= 0; i--) {
          if (listECheckBoxItem.current[i]?.checked) result.set(i, cellData[i]);
        }

        return result;
      },
      headCheckbox() {
        return eCheckBoxSelectAll.current;
      },
    }));

    return (
      <FlowBiteTable id={id} hoverable={true} {...props}>
        <FlowBiteTable.Head
          {...headProps}
          className={twMerge('bg-blue-900 text-xs uppercase text-gray-100', headProps?.className)}>
          {headCellData?.length && !hideCheckbox && (
            <FlowBiteTable.HeadCell {...headCellProps} className={twMerge('', headCellProps?.className)}>
              <Checkbox
                {...checkboxProps}
                ref={eCheckBoxSelectAll}
                onClick={(e) => {
                  for (let i = listECheckBoxItem.current.length - 1; i >= 0; i--) {
                    const itemCheckbox = listECheckBoxItem.current[i];
                    if (itemCheckbox) itemCheckbox.checked = !!eCheckBoxSelectAll.current?.checked;
                  }
                }}
              />
            </FlowBiteTable.HeadCell>
          )}
          {headCellData?.map((data, i) => (
            <FlowBiteTable.HeadCell
              key={`${id}-headcell-${i}`}
              {...headCellProps}
              className={twMerge('', headCellProps?.className)}>
              {data}
            </FlowBiteTable.HeadCell>
          ))}
        </FlowBiteTable.Head>
        <FlowBiteTable.Body {...bodyProps} className={twMerge('divide-y', bodyProps?.className)}>
          {cellData.map((chunk, i) => (
            <FlowBiteTable.Row
              {...rowProps}
              id={`${id}-row-${i}`}
              key={`${id}-row-${i}`}
              className={twMerge('bg-white dark:border-gray-700 dark:bg-gray-800', rowProps?.className)}>
              {!hideCheckbox && (
                <FlowBiteTable.Cell {...cellProps} className={twMerge('', rowProps?.className)}>
                  <Checkbox
                    {...checkboxProps}
                    ref={(ref) => {
                      listECheckBoxItem.current[i] = ref;
                    }}
                  />
                </FlowBiteTable.Cell>
              )}
              <FlowBiteTable.Cell
                {...cellProps}
                className={twMerge(
                  'whitespace-nowrap font-medium text-gray-900 dark:text-white',
                  cellProps?.className,
                )}>
                {chunk[0]}
              </FlowBiteTable.Cell>
              {chunk.map((data, i) =>
                i ? (
                  <FlowBiteTable.Cell
                    {...cellProps}
                    key={`${id}-cell-${i}`}
                    className={twMerge('', cellProps?.className)}>
                    {data}
                  </FlowBiteTable.Cell>
                ) : (
                  ''
                ),
              )}
            </FlowBiteTable.Row>
          ))}
        </FlowBiteTable.Body>
      </FlowBiteTable>
    );
  },
);
