export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export class LoginRes {
  id: string;
  email: string;
  username: string;
  role: UserRole;
  accessToken: string;
}
