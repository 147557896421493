const map: { [key: string]: number } = {};

const genId = function* (prefix = '_') {
  while (true) {
    map[prefix] = map[prefix] || 0;
    yield map[prefix]++;
  }
};

export const uniqueId = (prefix = '_') => `${prefix}${genId(prefix).next().value}`;
