export const environment = {
  production: false,
  enpoint: 'http://153.121.42.225:3100/',
  // enpoint: 'http://192.168.66.224:3100/',
  asset: 'http://153.121.42.225/',
  // asset: 'http://localhost:3000/',
  googleAdsense: {
    client: 'ca-pub-8288550345322948',
    slot: {
      hMultiplex: '7401099448',
      vMultiplex: '8905752809',
      vRectangle: '4688689088',
      hRectangle: '1397915991',
      square: '9345028081',
    },
  },
};
