export function getSearchMap<Group = { [key: string]: string }>(
  search: URLSearchParams,
  config?: Partial<{
    groupByPrefix: string;
    valueParseToTrue: string;
    valueParseToFalse: string;
    keyParseToNumber: { [key in keyof Group]: boolean } | { [key: string]: boolean };
  }>,
): { [key: string]: any; groupByPrefix: Group } {
  const { groupByPrefix, valueParseToFalse, valueParseToTrue, keyParseToNumber } = {
    groupByPrefix: 'f_',
    valueParseToTrue: 'true',
    valueParseToFalse: 'false',
    ...config,
  };
  const res = { groupByPrefix: {} } as any;

  search.forEach((v, k) => {
    let value;

    switch (v) {
      case valueParseToTrue:
        value = true;
        break;
      case valueParseToFalse:
        value = false;
        break;
      default:
        value = v;
    }

    if (((keyParseToNumber as any) || {})[k] === true) value = +v;

    if (groupByPrefix && k.startsWith(groupByPrefix)) res['groupByPrefix'][k.split(groupByPrefix)[1]] = value;
    else res[k] = value;
  });

  return res;
}
