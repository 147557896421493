import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fpost } from 'src/lib/fetch';
import { Select } from '../../../../lib/components/Select.component';
import { CreateUserReq } from '../../dto/user/user.req';

export function CreateUserPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextUsername = useRef<HTMLInputElement>(null);
  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextRole = useRef<HTMLSelectElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);
  const eTextRePassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

   // サイドヘッダーオープン
   const [isOpen, setIsOpen] = useState(false);

   const handleClick = () => {
     setIsOpen(!isOpen);
   };
 
   useEffect(() => {
     if (isOpen) {
       document.body.classList.add('open');
     } else {
       document.body.classList.remove('open');
     }
   }, [isOpen]);
   // サイドヘッダーオープン END

  const navigate = useNavigate();

  function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();
    if (eTextPassword.current?.value != eTextRePassword.current?.value) {
      eTextRePassword?.current?.setCustomValidity('Password does not match.')
      return;
    }

    // api/user/create
    fpost<CreateUserReq>('/user/createUser', {
      body: {
        username: eTextUsername.current?.value || '',
        email: eTextEmail.current?.value || '',
        role: eTextRole.current?.value || '',
        password: eTextPassword.current?.value || '',
      },
    }).then((data) => {
      if (data.length) navigate('..');
      else toasting({ children: 'このメールは登録済みです', containerProps: { className: 'border-red-600' } });
    });
  }

  return (
    <form className="flex flex-row flex-wrap px-16 py-10 dashboard-wrap-top" ref={eForm}>
      <div className='login-header-wrap'>
          <h1 className="text-2xl">ユーザー追加</h1>
          <div className={`dashboard-btn-div cp_hmenuac01${isOpen ? ' active' : ''}`} onClick={handleClick}><span className="cp_bar"></span></div>
        </div>
      <span
        className="w-full border-b border-slate-800"
        style={{
          minWidth: '24rem',
        }}></span>

      <div className="flex w-1/3 flex-row flex-wrap  edit-wrap-top">
        <TextInput
          labelProps={{
            children: '氏名',
          }}
          type="text"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextUsername}
        />

        <TextInput
          labelProps={{
            children: 'メールアドレス',
          }}
          type="email"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextEmail}
        />

        <Select
          className="rounded-md"
          labelProps={{
            children: '役割',
          }}
          containerProps={{
            className: "mt-4 w-1/3 basis-full',*/",
            style: {
              minWidth: '24rem',
            },
          }}
          ref={eTextRole}>
          <option value="USER">USER</option>
          <option value="ADMIN">ADMIN</option>
        </Select>

        <TextInput
          labelProps={{
            children: 'パスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextPassword}
        />

        <TextInput
          labelProps={{
            children: 'パスワード再入力',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextRePassword}
        />

        <div
          className="mt-8 flex w-1/3 basis-full flex-col items-center"
          style={{
            minWidth: '24rem',
          }}>
          <Button
            className="w-1/2 basis-full justify-center rounded-md bg-black text-xs text-white btn-submit"
            style={{
              minWidth: '256px',
            }}
            type="submit"
            onClick={handleButtonSubmit}
            ref={eButtonSubmit}>
            追加
          </Button>
          <Link
            className="col-span-full mx-auto mt-4 min-w-[3rem] cursor-pointer select-none text-center hover:underline hover:underline-offset-4 link-return"
            to="..">
            戻る
          </Link>
        </div>
      </div>
    </form>
  );
}
