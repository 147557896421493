import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { environment } from 'src/environments/environment';
import { Button } from 'src/lib/components/Button.component';
import { InputFile } from 'src/lib/components/InputFile.component';
import { fupload } from 'src/lib/fetch';
import { twMerge } from 'tailwind-merge';

export function ImportAdsPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eInputFile = useRef<HTMLInputElement>(null);
  const eSVGLoading = useRef<SVGSVGElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  async function handleButtonSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    if (eButtonSubmit.current && eSVGLoading.current && eInputFile.current) {
      eButtonSubmit.current.disabled = true;
      eButtonSubmit.current.className = twMerge(eButtonSubmit.current.className, 'hidden');
      eSVGLoading.current.classList.add('inline-block');
      eSVGLoading.current.classList.remove('hidden');

      const fileData = new FormData();

      fileData.append('file', eInputFile.current?.files?.[0] || '');

      const params: any = {
        // RIVER_NAME: eTextName.current?.value || '',
        // REGION_NAME: eSelectRegionName.current?.value || '',
      };

      const enpoint = Object.keys(params).reduce((e, paramKey) => {
        if (params[paramKey]) e.searchParams.set(paramKey, params[paramKey]);
        return e;
      }, new URL('media/upload', `${environment.enpoint}`));

      fupload(enpoint, {
        body: fileData,
        onErrorMoveToLocation: '/dashboard/logout',
      }).then(() => {
        navigate('..');
      });
    }
  }

  return (
    <form className="flex h-full flex-col justify-center gap-8 px-16 py-10" ref={eForm}>
      <InputFile
        containerProps={{
          className: 'text-xs text-center',
        }}
        accept=".csv"
        required
        ref={eInputFile}>
        <div className="inline-flex flex-nowrap items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.75}
            stroke="currentColor"
            className="mr-2 h-4 w-4">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
            />
          </svg>
          CSV
        </div>
      </InputFile>

      <Button
        className="mx-auto inline-flex items-center gap-2 rounded-md bg-black text-xs text-white"
        style={{
          minWidth: '256px',
        }}
        type="submit"
        onClick={handleButtonSubmit}
        ref={eButtonSubmit}>
        インポート
      </Button>
      <svg
        className="mx-auto hidden h-6 w-6 animate-spin text-yellow-500"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        ref={eSVGLoading}>
        <path stroke="none" d="M0 0h24v24H0z" /> <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
      </svg>
      <Link
        className="col-span-full mx-auto mt-4 min-w-[3rem] cursor-pointer select-none text-center hover:underline hover:underline-offset-4"
        to="..">
        戻る
      </Link>
    </form>
  );
}
