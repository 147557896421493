import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, isUserHasRoles } from 'src/lib/fetch';
import { UserRole } from '../dto/login/login.res';

export const DashboardSideBar = React.memo(
  () => {
    const navigate = useNavigate();

    const user = getUser();

    return (
      <aside id="dashboard-sidebar" className="dark" aria-label="Sidebar">
        <div className="custom-scrollbar-1 flex h-full flex-col overflow-y-scroll bg-gray-50 py-3 px-2 dark:bg-black">
          <ul className="mt-4 flex-1 space-y-3">
            <span className="self-center whitespace-nowrap text-lg font-semibold uppercase dark:text-white">
              Content
            </span>
            <li
              onClick={() => {
                navigate('ads');
              }}>
              <a
                href="#"
                className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>

                <span className="ml-2 flex-1 whitespace-nowrap">素材管理</span>
              </a>
            </li>
            {isUserHasRoles([UserRole.ADMIN]) && (
              <li
                onClick={() => {
                  navigate('listImports');
                }}>
                <a
                  href="#"
                  className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                    />
                  </svg>

                  <span className="ml-2 flex-1 whitespace-nowrap">インポート管理</span>
                  {/* <span className="ml-3 inline-flex items-center justify-center rounded-full bg-gray-200 px-2 text-sm font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                  Pro
                </span> */}
                </a>
              </li>
            )}
            {isUserHasRoles([UserRole.ADMIN]) && (
              <li
                onClick={() => {
                  navigate('approveList');
                }}>
                <a
                  href="#"
                  className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>

                  <span className="ml-2 flex-1 whitespace-nowrap">申請管理</span>
                  {/* <span className="ml-3 inline-flex h-3 w-3 items-center justify-center rounded-full bg-blue-200 p-3 text-sm font-medium text-blue-600 dark:bg-blue-900 dark:text-blue-200">
                  3
                </span> */}
                </a>
              </li>
            )}
          </ul>

          <ul className="space-y-3">
            <span className="mt-auto self-center whitespace-nowrap text-lg font-semibold uppercase dark:text-white">
              Others
            </span>
            <li
              onClick={() => {
                navigate('me', { state: user });
              }}>
              <a
                href="#"
                className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  {' '}
                  <path stroke="none" d="M0 0h24v24H0z" /> <circle cx="12" cy="7" r="4" />{' '}
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>

                <span className="ml-2 flex-1 whitespace-nowrap">{`${user?.username} (${user?.role})`}</span>
              </a>
            </li>
            {isUserHasRoles([UserRole.ADMIN]) && (
              <li
                onClick={() => {
                  navigate('users');
                }}>
                <a
                  href="#"
                  className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      viewBox="0 0 24 24"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                  </svg>

                  <span className="ml-2 flex-1 whitespace-nowrap">管理アカウント設定</span>
                </a>
              </li>
            )}
            <li
              onClick={() => {
                navigate('logout');
              }}>
              <a
                href="#"
                className="flex items-center rounded-md px-1.5 py-2 text-sm font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  {' '}
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /> <polyline points="16 17 21 12 16 7" />{' '}
                  <line x1="21" y1="12" x2="9" y2="12" />
                </svg>

                <span className="ml-2 flex-1 whitespace-nowrap">ログアウト</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    );
  },
  (prevProps, currentProps) => true,
);
