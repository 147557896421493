import { AdsList } from '../components/AdsList.component';
import { AdsSideBar } from '../components/AdsSideBar.component';
import '../css/ads.css';

export function AdsClientPage() {
  return (
    <>
      <AdsSideBar />
      <AdsList />
    </>
  );
}
